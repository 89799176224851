<div class="block justify-between md:flex items-center mb-2">
  <div>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="E-Invoice Com" name="einvoice" class="w-full" />
      <el-tab-pane label="Com & Invoice" name="company_and_invoice" class="w-full" :disabled="selectCurrentCompany == -1"/>
      <el-tab-pane label="Movement" name="movement" class="w-full" :disabled="selectCurrentCompany == -1"/>
    </el-tabs>
  </div>
</div>

<div class="mt-5 rounded">
  <div v-if="activeTab == 'einvoice'" class="rounded bg-white px-5 py-3 shadow-md">
      <DigitalInvoices />
  </div>

  <div v-show="activeTab == 'company_and_invoice'" class="grid grid-cols-1 xl:grid-cols-3 gap-6 my-5">
    <div class="col-span-1 flex justify-between xl:flex-col gap-6 w-full">
      <div class="rounded bg-white px-5 py-3 shadow-md xl:h-1/3 shrink-0 items-center">
        <h1 class="font-bold text-gray-500"><i class="el-icon-document"></i> Hoá đơn</h1>
        <div class="flex items-center justify-center h-full">
          <div class="text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold big-number">
            {{formatNumber(totalInvoice)}}
          </div>
        </div>
      </div>
      <div class="rounded bg-white px-5 py-3 shadow-md xl:h-1/3 shrink-0 w-full">
        <h1 class="font-bold text-gray-500">
          <i class="el-icon-office-building"></i> Công ty
        </h1>
        <div class="flex flex-col items-center justify-center h-full gap-1 md:gap-6">
          <div class="text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold big-number">
            {{formatNumber(totalCompany)}}
          </div>
          <div class="text-xl xs:text-2xl sm:text-3xl md:text-4xl font-bold big-number">
            <i class="el-icon-office-building" />
            {{formatNumber(totalCompanyGov)}}
          </div>
        </div>
      </div>
      <div class="rounded bg-white px-5 py-3 shadow-md xl:h-1/3 shrink-0 w-full">
        <h1 class="font-bold text-gray-500"><i class="el-icon-user"></i> Người dùng</h1>
        <div class="flex items-center justify-center h-full">
          <div class="text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold big-number">
            {{formatNumber(totalUser)}}
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-2 rounded bg-white px-5 py-3 shadow-md">
      <canvas ref="topCompaniesChart" id="topCompaniesChart" />
    </div>
  </div>

  <div v-show="activeTab == 'movement'" class="grid grid-cols-1 lg:grid-cols-2 gap-6">
    <div class="col-span-1 flex rounded bg-white px-5 py-3 shadow-md h-full">
      <canvas ref="topUsersChart" id="topUsersChart" class="h-full" />
    </div>
    <div class="col-span-1 flex rounded bg-white px-5 py-3 shadow-md h-full">
      <canvas
        ref="topUsersOwnCompanyChart"
        id="topUsersOwnCompanyChart"
        class="h-full"
      />
    </div>
  </div>

  <div v-show="activeTab == 'movement'" class="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
    <div class="col-span-1 rounded bg-white px-5 py-3 shadow-md">
      <canvas ref="invoiceHistoryChart" id="invoiceHistoryChart" />
    </div>
    <div class="col-span-1 rounded bg-white px-5 py-3 shadow-md">
      <canvas ref="companyHistoryChart" id="companyHistoryChart" />
    </div>
  </div>
</div>

import { URL } from "@/api/dashBoard.js";
import formatNumber from "@/plugins/formatNumber.js";
import {
  Chart,
  LineElement,
  BarElement,
  ArcElement,
  DoughnutController,
  BarController,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  Tooltip,
  SubTitle,
  PieController,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import DigitalInvoices from "./digital-invoices/digital-invoices.vue";



Chart.register(
  LineElement,
  BarElement,
  ArcElement,
  PieController,
  DoughnutController,
  BarController,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Title,
  Tooltip,
  SubTitle
);

export default {
  data() {
    return {
      topUsersData: {
        type: "bar",
        data: {
          labels: [],
          datasets: [
            {
              backgroundColor: "rgb(55, 161, 235)",
              data: [],
              datalabels: {
                align: "end",
                anchor: "start",
              },
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: "Top Users sử dụng hệ thống (Ngày)",
              padding: 1,
            },
            datalabels: {
              color: "white",
              display: function (context) {
                return context.dataset.data[context.dataIndex] > 0;
              },
              font: {
                weight: "bold",
              },
              formatter: Math.round,
            },
          },
          // Core options
          aspectRatio: 5 / 3,
          layout: {
            padding: {
              top: 24,
              right: 16,
              bottom: 0,
              left: 8,
            },
          },
          elements: {
            line: {
              fill: false,
            },
            point: {
              hoverRadius: 7,
              radius: 5,
            },
          },
          scales: {
            xAxes: [{
              ticks: {
                callback: function(value) {
                  return value.substr(0, 10);//truncate
                },
              }
            }],
            yAxes: [{}]
          },
        },
        plugins: [ChartDataLabels],
      },
      topUserOwnCompanyData: {
        type: "bar",
        data: {
          labels: [],
          datasets: [
            {
              backgroundColor: "rgb(55, 161, 235)",
              data: [],
              datalabels: {
                align: "end",
                anchor: "start",
              },
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: "Top Users quản lý nhiều công ty nhất",
              padding: 1,
            },
            datalabels: {
              color: "white",
              display: function (context) {
                return context.dataset.data[context.dataIndex] > 1;
              },
              font: {
                weight: "bold",
              },
              formatter: Math.round,
            },
          },

          // Core options
          aspectRatio: 5 / 3,
          layout: {
            padding: {
              top: 24,
              right: 16,
              bottom: 0,
              left: 8,
            },
          },
          elements: {
            line: {
              fill: false,
            },
            point: {
              hoverRadius: 7,
              radius: 5,
            },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
        plugins: [ChartDataLabels],
      },
      invoiceHistoryData: {
        type: "line",
        data: {
          labels: [1, 2, 3, 4, 5, 6],
          datasets: [
            {
              backgroundColor: "rgb(255, 55, 132)",
              borderColor: "rgb(255, 55, 132)",
              data: [],
              datalabels: {
                align: "top",
                anchor: "top",
              },
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: "Số lương hoá đơn trong 6 tháng gần nhất",
              padding: 1,
            },
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              borderRadius: 4,
              color: "white",
              font: {
                weight: "bold",
                size: "10px",
              },
              formatter: (value) => {
                return new Intl.NumberFormat("de-DE").format(value);
              },
              padding: 2,
            },
          },

          // Core options
          aspectRatio: 5 / 3,
          layout: {
            padding: {
              top: 32,
              right: 16,
              bottom: 16,
              left: 8,
            },
          },
          elements: {
            line: {
              fill: false,
            },
          },
          scales: {
            y: {
              stacked: true,
            },
          },
        },
        plugins: [ChartDataLabels],
      },

      companyHistoryData: {
        type: "line",
        data: {
          labels: [1, 2, 3, 4, 5, 6],
          datasets: [
            {
              backgroundColor: "rgb(73, 192, 192)",
              borderColor: "rgb(73, 192, 192)",
              data: [],
              datalabels: {
                align: "top",
                anchor: "top",
              },
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: "Số lương công ty trong 6 tháng gần nhất",
              padding: 1,
            },
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              borderRadius: 4,
              color: "white",
              font: {
                weight: "bold",
                size: "10px",
              },
              formatter: (value) => {
                return new Intl.NumberFormat("de-DE").format(value);
              },
              padding: 2,
            },
          },

          // Core options
          aspectRatio: 5 / 3,
          layout: {
            padding: {
              top: 32,
              right: 16,
              bottom: 16,
              left: 8,
            },
          },
          elements: {
            line: {
              fill: false,
            },
          },
          scales: {
            y: {
              stacked: true,
            },
          },
        },
        plugins: [ChartDataLabels],
      },
      topCompaniesData: {
        type: "pie",
        data: {
          labels: [],
          datasets: [
            {
              label: "TopCompanies",
              data: [],
              datalabels: { anchor: "end" },
              backgroundColor: [
                "#ed4289",
                "#10d32a",
                "#75db0f",
                "#e0797c",
                "#87603e",
                "#7c554f",
                "#84ced6",
                "#1ba7e2",
                "#e5c3e4",
                "#352f2c",
                "#915a9b",
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: "Top các công ty có số lượng hoá đơn lưu trữ",
            },
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              borderColor: "white",
              borderRadius: 25,
              borderWidth: 2,
              color: "white",
              display: true,
              font: {
                weight: "bold",
                size: "10px",
              },
              padding: 4,
              formatter: (value, context) => {
                const total = context.chart.data.datasets[0].data.reduce(
                  (total, datapoint) => total + datapoint,
                  0
                );
                const percentage = ((value / total) * 100).toFixed(2);
                const label = this.truncate(
                  context.chart.data.labels[context.dataIndex],
                  30
                );
                const number = new Intl.NumberFormat("de-DE").format(value);
                return `${label} - ${number} - ${percentage}%`;
              },
            },
          },

          // Core options
          aspectRatio: 4 / 3,
          cutoutPercentage: 32,
          layout: {
            padding: 32,
          },
          elements: {
            line: {
              fill: false,
            },
            point: {
              hoverRadius: 7,
              radius: 5,
            },
          },
        },
        plugins: [ChartDataLabels],
      },
      invoiceHistoryChart: null,
      companyHistoryChart: null,
      topCompaniesChart: null,
      topUsersChart: null,
      topUsersOwnCompanyChart: null,
      totalCompany: 0,
      totalCompanyGov: 0,
      totalInvoice: 0,
      totalUser: 0,
      activeTab: 'einvoice'
    };
  },

  async created() {
    await this.getSystemStatistic();
    this.initChart();
  },
  components: {
    DigitalInvoices,
  },
  methods: {
    initChart() {
      this.companyHistoryChart = new Chart(
        this.$refs.companyHistoryChart,
        this.companyHistoryData
      );
      this.invoiceHistoryChart = new Chart(
        this.$refs.invoiceHistoryChart,
        this.invoiceHistoryData
      );
      this.topCompaniesChart = new Chart(
        this.$refs.topCompaniesChart,
        this.topCompaniesData
      );
      this.topUsersChart = new Chart(
        this.$refs.topUsersChart,
        this.topUsersData
      );
      this.topUsersOwnCompanyChart = new Chart(
        this.$refs.topUsersOwnCompanyChart,
        this.topUserOwnCompanyData
      );
    },
    truncate(fullStr, strLen, separator) {
      if (fullStr.length <= strLen) return fullStr;

      separator = separator || "...";

      var sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2);

      return (
        fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars)
      );
    },
    async getSystemStatistic() {
      const result = await this.$request({
        url: URL.SYSTEM_STATISTIC,
      });

      const res = result.data;
      if (res.code == 200) {
        const topCompanyData = res.data.dynamic_data.topCompanyData;
        const topUserData = res.data.dynamic_data.topUserData;
        const topUserOwnCompanyData = res.data.dynamic_data.topUserOwnCompanyData;
        const companyHistoryData = res.data.dynamic_data.companyHistoryData;
        const invoiceHistoryData = res.data.dynamic_data.invoiceHistoryData;

        this.totalCompany = res.data.total_company;
        this.totalCompanyGov = res.data.total_company_gov;
        this.totalInvoice = res.data.total_invoice;
        this.totalUser = res.data.total_user;
        this.topCompaniesData.data.labels = topCompanyData.labels;
        this.topCompaniesData.data.datasets[0].data = topCompanyData.data;

        this.topUsersData.data.labels = topUserData.labels;
        this.topUsersData.data.datasets[0].data = topUserData.data;

        this.topUserOwnCompanyData.data.labels = topUserOwnCompanyData.labels;
        this.topUserOwnCompanyData.data.datasets[0].data = topUserOwnCompanyData.data;

        this.companyHistoryData.data.labels = companyHistoryData.labels;
        this.companyHistoryData.data.datasets[0].data = companyHistoryData.data;

        this.invoiceHistoryData.data.labels = invoiceHistoryData.labels;
        this.invoiceHistoryData.data.datasets[0].data = invoiceHistoryData.data;
      }
    },

    formatNumber,
  },

  computed: {},
};
